import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Braces from "../../components/service-items/braces";
import { colors } from "../../styles/variables";

const BracesPage = () => (
  <Layout bgColor={colors.light}>
    <SEO title="Braces" />
    <Braces />
  </Layout>
);

export default BracesPage;
