import React from "react";
import { Link } from "gatsby";
import Image from "./Images";
import {
  Container,
  ImageContainer,
  ContentsContainer,
  Title,
  SubTitle,
  Text,
  Wrapper,
  SecondWrapper,
  BannerImageMD,
  BannerImageSM,
  DotIcon,
  ListBox,
  StyledImg,
} from "./styles";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import BannerImages from "../BannerImgs";

const Braces = () => {
  const image = Image();
  return (
    <>
      <Breadcrumb pageTitle="">
        <BreadcrumbItem Link="/what-we-do" Text="What We Do" />
        <BreadcrumbItem Link="/what-we-do/braces" Text="Braces" />
      </Breadcrumb>
      <BannerImages imagesrc="braces" />
      <PageContainer>
        <Container>
          <Title>Braces</Title>
          <Wrapper>
            <ContentsContainer>
              <Text>
                There are so many orthodontic treatment options to choose from!
              </Text>
              <Text>
                Conventional metal braces are the traditional time-tested way to
                align and straighten over-crowded and misaligned teeth. They are
                appropriate for children, adolescents or adults and are
                generally more affordable than other alternatives. Additionally,
                they are super effective in aligning teeth and can sometimes be
                quicker when fixing specific concerns, especially those complex
                issues.
              </Text>
              <Text>
                Some people, particularly adults, may be concerned about the
                appearance of metal braces; however, these braces are becoming
                increasingly subtle. Metal braces involve brackets bonded to
                each individual tooth. There are then archwires placed with and
                elastic bands to help move teeth into the correct positions.
                Saying that, there are numerous advancements in metal braces
                recently! These include:
              </Text>
              <ListBox>
                <DotIcon />
                <p>Smaller brackets</p>
              </ListBox>
              <ListBox>
                <DotIcon />
                <p>Thinner or tooth coloured archwires</p>
              </ListBox>
              <ListBox>
                <DotIcon />
                <p>Clear elastic bands are available</p>
              </ListBox>
              <br />
              <Text>
                We don’t have to take away all the fun though; if you want to
                add a bit of customised colour to your treatment, there are
                options for colours around your braces and rubber bands.
              </Text>
            </ContentsContainer>
            <ImageContainer>
              <StyledImg fluid={image.braces7} />
            </ImageContainer>
          </Wrapper>
          <SecondWrapper>
            <ImageContainer>
              <StyledImg fluid={image.girl61} />
            </ImageContainer>
            <ContentsContainer>
              <SubTitle>Why do we use metal braces?</SubTitle>

              <Text>
                Many people choose braces to straighten their teeth, but they
                also serve an important role in dental health.
              </Text>
              <Text>
                Teeth that are crowded or poorly aligned can be more difficult
                to clean which may result in gum disease or tooth decay. Other
                things that can be affected include speech, chewing difficulties
                and abnormal wear to teeth. Ideally, braces can help prevent
                these types of problems before they become severe.
              </Text>
              <Text>
                Metal braces are really good at closing gaps between teeth and
                fixing complex issues including crossbites, overbites,
                underbites, openbites, crooked teeth, gaps and other tooth and
                jaw issues. They work by applying continuous pressure to slowly
                move teeth over a period of time. As your teeth move, your bone
                will also change shape around the teeth. Ideally, as the teeth
                move, they are guided along an archwire which is also used to
                coordinate the teeth on the top and bottom jaw to obtain a
                perfect bite! The aim is to achieve an improved fit of all the
                teeth and healthier bite.
              </Text>
              <Text>
                At Gippsland Orthodontics, we know that every person and their
                mouth is different, so we tailor metal braces to each person. We
                work to fit the treatment to you and want to find the
                appropriate approach and treatment to help you achieve the smile
                you want.
              </Text>
              <SubTitle>Any extra tips for braces?</SubTitle>
              <Text>
                Yes! Life is pretty much the same with braces – just with some
                little extra care required. Daily activities will be the same
                including sports, eating, drinking and having to take care of
                your teeth! You will have to spend a little longer brushing your
                teeth, gums and mouth. There will also be some foods and drinks
                to be careful of. Special mouthguards can be worn for sports
                too. But guess what, these little things will benefit that smile
                when the treatment is finished!
              </Text>
              <Text>
                Wondering about the options are available to you or wanting to
                improve that smile? Give Gippsland Orthodontics a call or
                contact us <Link to={`/contact`}>here</Link>
              </Text>
            </ContentsContainer>
          </SecondWrapper>
        </Container>
      </PageContainer>
    </>
  );
};

export default Braces;
